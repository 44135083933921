const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://andrewnickerson.dev',
  title: 'AN.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'Andrew',
  role: 'developer / designer',
  description:
    "Hi, I'm Andrew! I recently graduated from Harvard College, class of 2024, where I majored in Computer Science with a citation in Spanish. I'm now a software engineer at Snap Inc. on the Growth and Insights team, where I also interned during the summers of 2022 and 2023. My passions lie in software engineering, social impact tech, and music. During my time at Harvard, I was a teaching fellow for Introduction to Algorithms and their Limitations (CS 120) and was active in several social organizations, including Harvard Tech for Social Good, Harvard College Radio, and the Harvard-Radcliffe Orchestra.",
  resume:
    'https://drive.google.com/file/d/1stCAzVGMfyc95NlCuLbg_wd8DMSa__oW/view?usp=sharing',
  social: {
    linkedin: 'https://www.linkedin.com/in/andrewnickerson484/',
    github: 'https://github.com/atnick484',
  },
}

const projects = [
  {
    name: 'Can you write a hit song?',
    description:
      'An interactive data visualization story using Billboard Top 100 Data in combination with the Spotify and Genius Lyric APIs',
    stack: ['D3.js', 'JavaScript', 'HTML', 'CSS', 'Python', 'Pandas', 'Numpy'],
    sourceCode: 'https://github.com/atnick484/cs171hitsongs',
    livePreview: 'https://atnick484.github.io/cs171hitsongs/',
  },
  {
    name: 'MusicMatch',
    description:
      'Using Spotify API and Machine Learning for Audio Feature Visualizations and Song Recommendations',
    stack: ['Python', 'Jupyter Notebooks'],
    sourceCode: 'https://github.com/atnick484/musicmatch',
    livePreview:
      'https://deepnote.com/workspace/atnick484-4b9f-574e258c-4f24-4045-a86f-0a3130fabd89/project/MusicMatch-Duplicate-18111633-1a31-4061-b1bc-1fa6a05a8a81/%2F.cache',
  },
  {
    name: 'Mission: Mentor',
    description:
      'Ed-tech startup (raised $8k+) that gives students personalized college advice based on their academic profile, background, interests, and goals',
    stack: ['React', 'JavaScript', 'SQL', 'AWS Cognito/RDS/Lambda'],
    livePreview: 'https://www.missionmentor.org/',
  },
  {
    name: 'BeachFinder',
    description:
      'Web application that helps beach goers find the best beaches (and best weather) given a location',
    stack: ['Python', 'Flask', 'SQL'],
    sourceCode: 'https://github.com/atnick484/beachfinder',
    livePreview: 'https://www.youtube.com/watch?v=R3VpAYjpoA4&t=3s',
  },
  {
    name: 'ChIP-Seq Data Research @ Stanford',
    description:
      'Large-scale meta-analysis of chromatin immunoprecipitation-sequenced data to exhibit histone modification relationships (under Stanford Institutes of Medical Research program)',
    stack: ['R', 'SQL'],
    sourceCode: 'https://github.com/atnick484/simr2019',
  },
  {
    name: 'CDC NIOSH Lab Condition Predictor',
    description:
      'ML web application that allows National Institute for Occupational Safety & Health to predict future lab temperature and humidity conditions',
    stack: ['Next.js', 'TypeScript', 'GraphQL', 'scikit-learn'],
    sourceCode: 'https://github.com/hcs-t4sg/NIOSH',
  },
  {
    name: 'Harvard University Dining Services Analysis',
    description:
      "Environmental analysis on Harvard University's disposable container dining program",
    stack: ['Python', 'Pandas', 'Numpy'],
    sourceCode: 'https://github.com/atnick484/huds',
    livePreview: 'https://www.hodp.org/project/HUDS',
  },
  {
    name: 'Discriminology App Design',
    description:
      'Mobile application design that allows community members to log and report their experiences of racial inequity',
    stack: ['Figma', 'UX'],
    livePreview:
      'https://drive.google.com/file/d/1MRbq5OC1GSlQdyEB0evwOA5e60f906qJ/view',
  },
  {
    name: 'ACLU Case Manager Design',
    description:
      'Design of case management system for state-level offices of the American Civil Liberties Union (ACLU)',
    stack: ['Figma', 'UX'],
    livePreview:
      'https://drive.google.com/file/d/15SEoh9hrXIC9moXw5KtHn8gs9JVtPe4u/view',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Python (scikit-learn)',
  'Objective-C',
  'Java',
  'C',
  'SQL',
  'JavaScript/TS (React, D3.js)',
  'HTML',
  'CSS',
  'OCaml',
  'AWS (Cognito/RDS/Amplify/Lambda)',
  'Flask',
  'Next.js',
  'Figma',
  'XCode',
  'Tableau',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'atnickerson484@gmail.com',
}

export { header, about, projects, skills, contact }
